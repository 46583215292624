.blog-page {
  padding: 2rem;
  justify-content: center;
  align-items: center;
}

.blog-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

.blog-grid .blog-list-card {
  padding: 1rem; /* Adjust the padding as needed */
  margin-bottom: 1rem; /* Adjust the margin as needed */
}

@media screen and (min-width: 768px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.load-more-button {
  display: flex;
  color: var(--clr-light-green);
  justify-content: center;
  font-weight: 600;
  padding: 6px 0.8rem;
  margin-top: 1.5rem;
}

.load-more-button button {
  padding: 1rem 1.5rem;
  color: var(--clr-light-green);
  display: flex;
  align-items: center;
  font-weight: 600;
  background: black;
  padding: 6px 0.8rem;
  border: 1px solid var(--clr-light-green);
  border-radius: 30px;
}

.load-more-button button:hover {
  color: #ffffff;
  border-color: #ffffff;
}
