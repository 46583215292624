.editor_container {
  max-width: 80%;
  margin: 0 auto;
}

.editor_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editor_container > form > button,
.editor_flex button {
  color: var(--clr-light-green);
  display: flex;
  align-items: center;
  font-weight: 600;
  background: black;
  padding: 6px 1.5rem;
  border: 1px solid var(--clr-light-green);
  border-radius: 5px;
}

.post_title_label {
  font-size: var(--text-xl);
  margin: 5px 0;
  letter-spacing: 1px;
}

.post_title_input {
  font-size: var(--text-medium);
  padding: 5px 2px;
  border: 1px solid var(--clr-light-green);
  width: 100%;
  outline: none;
  color: var(--text-white);
  margin: 1rem 0;
  border-radius: 5px;
}

.post_title_input:focus {
  border-color: var(--clr-light-green);
}

.dashboard_error {
  background-color: var(--clr-rose);
  padding: 2px 4px;
  color: var(--clr-white);
  font-size: var(--text-medium);
  text-align: center;
  margin: 5px 0;
}

/* ============ Draggable Image =================== */
.drag-area {
  position: relative;
  border: 2px dashed var(--clr-light-green);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  padding: 10px 0;
  border-radius: 5px;
}

.drag-area > input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drag-area:hover,
.drag-area.drag_over {
  opacity: 0.6;
}

h3 {
  margin-bottom: 20px;
  font-weight: 500;
}

.drag-area .icon {
  width: 100%;
  max-height: 600px;
  padding: 0 0.5rem;
  overflow: hidden;
}

.drag-area .icon > svg {
  font-size: 50px;
  fill: var(--clr-light-green);
}
.drag-area .icon > img {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
}

.drag-area .header {
  font-size: 20px;
  font-weight: 500;
  color: #34495e;
}

.drag-area .support {
  font-size: 12px;
  color: gray;
  margin: 10px 0 15px 0;
}

.drag-area .button {
  font-size: 20px;
  font-weight: 500;
  color: var(--clr-light-green);
  cursor: pointer;
}

.drag-area.active {
  border: 2px solid var(--clr-light-green);
}

.drag-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image_uploader h3 {
  margin: 0;
}

.image_uploader input {
  font-size: var(--text-medium);
  padding: 5px 2px;
  border: 1px solid var(--clr-light-green);
  width: 100%;
  outline: none;
  color: var(--text-white);
  border-radius: 5px;
  margin: 0.5rem 0 1rem 0;
}

/* CK Editor */
.ck.ck-editor__main > .ck-editor__editable {
  background: transparent !important;
  margin-bottom: 1rem;
}

.ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-focused {
  border-color: var(--clr-light-green) !important;
}

@media (max-width: 768px) {
  .editor_container {
    max-width: 100%;
  }
}
