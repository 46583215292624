.league-details-content {
  position: relative;
  margin: 2rem auto;
  width: 700px;
  min-height: 500px;
  max-height: 700px;

  padding: 36px 24px 36px 24px;
  border-radius: 12px;
  background: linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    ),
    linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    );

  border: 2px solid;

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );
}

.login-back-btns {
  margin-top: 1rem;
  margin-left: 1rem;
}

.login-back-btns button {
  color: #121212;
  background-color: #7fda56;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
}
.league-details-header-invite {
  position: absolute;
  right: 12px;
  top: 10px;
  gap: 15px;
}
.league-details-header-invite button {
  padding: 5px 25px;
  background-color: #22c55e;
  color: #121212;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  gap: 4px;
}

.league-details-header p {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  color: white;
}

.league-details-userlist {
  margin-top: 3rem;
}
.league-details-userlist-header {
  display: flex;
}
.league-details-userlist-header p {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
}

.league-details-userlist-header-left {
  flex: 6;
  display: flex;
  justify-content: space-between;
}
.league-details-userlist-header-right {
  flex: 6;
  display: flex;
  justify-content: space-between;
}

.deatils-hr {
  opacity: 0.1;
  border: 1px solid white;
}

.league-details-userlist-alldata {
  min-height: 200px;
  max-height: 500px;

  overflow-y: auto;
}
.league-details-userlist-items {
  display: flex;
}

.league-details-userlist-items-left {
  flex: 6;
  display: flex;
  justify-content: space-between;
}
.league-details-userlist-items-right {
  flex: 6;
  display: flex;
  justify-content: space-between;
}
.username {
  margin-right: -15px;
}
.league-details-footer {
  width: 90%;
  position: absolute;
  text-align: center;
  bottom: 25px;
}
.league-details-footer p {
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.league-details-footer p span {
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}

@media (max-width: 768px) {
  .league-details-content {
    width: 600px;
  }
}
@media (max-width: 768px) {
  .league-details-content {
    width: 370px;
  }

  .league-details-content {
    padding: 36px 10px 36px 10px;
  }

  .league-details-header-invite {
    position: absolute;
    right: 2px;
    top: 5px;
  }

  .league-details-header-invite button {
    padding: 5px 15px;

    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
  }

  .league-details-footer p {
    font-size: 14px;
    font-weight: 200;
    line-height: 15px;
  }

  .league-details-footer p span {
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
  }
}
