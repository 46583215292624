.developmodal {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0.1, 0.1, 0.1, 0.7);
}

.developmodal h1 {
  font-size: 18px;
}

.developmodal button {
  color: white;
  background-color: rgb(22, 71, 22);
}
.developmodal button:hover {
  color: white;
  background-color: rgb(22, 71, 22);
  border: none;
  color: gray;
}

@media (max-width: 768px) {
  .developmodal {
    padding-inline: 1rem;
    text-align: center;
  }

  .developmodal h1 {
    font-size: 15px;
  }
}
