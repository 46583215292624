.profiles {
  margin: 3rem auto;
  border: 1px solid red;
  width: 650px;
  min-height: 508px;
  border-radius: 12px;
  border: 2px;
  border: 2px solid;
  background: linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    ),
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );
}

.profile-content {
  padding-block: 1rem;
  padding-inline: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.p-header {
}

.p-header h3 {
  color: #d0cdcd;
  font-size: 24px;
  font-weight: 600;
}

.p-header-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.p-header-circle {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dfdfdf;
}
.p-heafer-user {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.p-heafer-user span#title {
  font-size: 28px;
  font-weight: 600;
}
.p-heafer-user span {
  font-size: 14px;
  font-weight: 600;
  margin-top: -5px;
}

.profile-forms {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.form-items {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.form-items label {
  font-size: 20px;
  font-weight: 600;

  color: #d0cdcd;
}
.form-items input {
  width: 100%;
  height: 37px;
  padding: 10px 16px 10px 16px;
  border: 1px;
  background: transparent;
  border: 1px solid #909090;
  font-size: 18px;
  color: #d0cdcd;
}

.profile-forgetpass {
  margin-top: 10px;
  text-align: end;
}

.profile-forgetpass u a {
  color: #3786fb;

  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
}
.profile-forgetpass u {
  color: #3786fb;
}

.profile-updatepass {
  margin-top: 10px;
  text-align: center;
}

.profile-updatepass button {
  background: #22c55e;
  color: #121212;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  padding: 13px;
  letter-spacing: 0.04em;

  box-shadow: 0px 10px 15px 0px #3d037826;
}

.profile-btn button {
  padding: 14px 12px;
  width: 100%;
  font-size: 16px;
  color: #121212;
  font-weight: 600;
  background: #22c55e;
  box-shadow: 0px 10px 15px 0px #3d037826;
}

.eye-icons {
  position: absolute;
  right: 0;
  top: 35px;
}

.profile-image {
  width: 55px;
  height: 55px;
  border: 1px solid white;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .profiles {
    width: 550px;
  }
}
@media screen and (max-width: 580px) {
  .profiles {
    width: 370px;
  }
  .profile-content {
    padding-inline: 0.8rem;
  }
}
