.blog_detail_container {
  max-width: 50%;
  margin: 0 auto;
}

.author_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.author_prefix .post_date {
  font-size: var(--text-x-sm);
}

.blog_detail_editor figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.blog_detail_editor img {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0;
}

/* Suggested Post Section */
.suggested_posts {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.suggested_posts_heading {
  font-size: 2rem; /* Increase the font size to make it bigger */
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex; /* Add display flex */
  justify-content: center; /* Align the heading horizontally */
  align-items: center; /* Align the heading vertically */
}

.suggested_posts_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

/* Screen wise layout */
@media (max-width: 1024px) {
  .blog_detail_container {
    max-width: 100% !important;
  }
  .blog_details_heading {
    font-size: var(--text-xl);
  }
  .suggested_posts_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .blog_detail_container {
    max-width: 100% !important;
  }
  .blog_details_heading {
    font-size: var(--text-large);
  }
  .suggested_posts_list {
    grid-template-columns: repeat(1, 1fr);
  }
}
