.signup-main {
  position: relative;
  height: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-back-btn {
  position: absolute;
  gap: 5px;
  top: 10px;
  left: 15px;
}
.login-back-btn button {
  color: #121212;
  background-color: #7fda56;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
}

.signup {
  margin-top: 4rem;
  opacity: 0.8;
  width: 550px;
  min-height: 500px;
  border-radius: 10px;
  background-color: rgba(0, 0.3, 0.5, 0.5);
  box-shadow: 2px 2px 5px black;
}

.login {
  opacity: 0.8;
  width: 550px;
  height: 400px;
  border-radius: 10px;
  background-color: rgba(0, 0.3, 0.5, 0.5);
  box-shadow: 2px 2px 5px black;
  margin-top: 4rem;
}
.profile {
  opacity: 0.8;
  width: 550px;
  min-height: 400px;
  border-radius: 10px;
  background-color: rgba(0, 0.3, 0.5, 0.5);
  box-shadow: 1px 1px 1px white;
  margin-top: 1rem;
  padding: 1rem;
}

.pass {
  opacity: 0.8;
  width: 550px;
  height: 300px;
  border-radius: 10px;
  background-color: rgba(0, 0.3, 0.5, 0.5);
  box-shadow: 1px 1px 1px white;
  margin-top: 4rem;
}

.forget-pass button {
  color: #0057ff;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}
.signup-body form .sub-btn {
  margin-top: 15px;
  width: 100%;
  height: 56px;
  padding: 10px;
  background-color: #7fda56;
  color: #121212;

  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
}

.header h2 {
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.signup-body {
  margin: auto;
  max-width: 396px;
}
.input-items {
  display: flex;
  justify-content: center;
}

.forget-passwrapper {
  width: 103%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forget-passwrapper input {
  width: 10px;
  background-color: red;
}

.chech-icon {
  margin-left: -10px;
}

.chech-icon span {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #9c9c9c;
}

.signup-body input {
  width: 100%;
  height: 56px;
  padding: 10px 16px 10px 16px;
  border: 1px;
  background-color: transparent;
  box-shadow: 0px 10px 15px 0px #1f003f1a;
  gap: 10px;
  border: 1px solid #303030;
}
.input-items .firstinput:focus {
  transition: 0.1s;
  border-left: 6px solid #7fda56;
}

.extra-btn {
  margin-top: 1.2rem;
  text-align: center;
  color: #9c9c9c;
}

@media screen and (max-width: 600px) {
  .signup-body {
    max-width: 350px;
  }
  .signup {
    padding-inline: 15px;
    max-width: 370px;
  }
  .login {
    padding-inline: 15px;
    max-width: 370px;
  }
  .pass {
    padding-inline: 15px;
    max-width: 370px;
  }

  .forget-pass button {
    font-size: 15px;
  }

  .extra-btn span {
    font-size: 13px;
  }

  .chech-icon {
    margin-left: -12px;
  }

  .chech-icon span {
    font-size: 12px;
  }

  .header h2 {
    font-size: 21px;
  }
}
