/* Big blog card */

.blog_container {
  width: 50%;
  padding: 3rem 0;
  margin: 0 auto;
}

.blog_container_wrapper {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.blog_wrapper {
  width: 100%;
  padding: 10px;
}

.blog_image_container {
  width: 100%;
}

.blog_list_wrapper {
  width: 100%;
}

.blog_image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.blog_heading {
  font-size: var(--text-xl);
  font-family: var(--font-poppins);
  display: block;
  display: -webkit-box; /* for Safari and old versions of Chrome */
  max-height: calc(
    1.2em * 4
  ); /* 1.2em is the line height and 4 is the number of lines */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical; /* for Safari and old versions of Chrome */
}

.author_wrapper {
  display: flex;
  gap: 1rem;
}

.author_name {
  font-size: var(--text-sm);
}

.post_date {
  font-size: var(--text-x-sm);
}

.blog_text {
  font-size: var(--text-medium);
  display: block;
  display: -webkit-box; /* for Safari and old versions of Chrome */
  /* 1.2em is the line height and 3 is the number of lines */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical; /* for Safari and old versions of Chrome */
}

/* Blog List card */

.blog_list_card_wrapper {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.blog_list_card {
  max-width: 300px;
  max-height: 200px;
}

.blog_list_card_img {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
}

.blog_list_card_title_date {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.blog_list_card_post_date {
  font-size: var(--text-x-sm);
  margin-top: -1rem;
  text-align: left;
}

.blog_list_card_title {
  font-size: var(--text-sm);
  display: block;
  display: -webkit-box; /* for Safari and old versions of Chrome */
  text-align: left;
  max-height: calc(
    1.2em * 4
  ); /* 1.2em is the line height and 3 is the number of lines */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical; /* for Safari and old versions of Chrome */
}

.blog_post_error {
  background-color: var(--clr-rose);
  padding: 2px 4px;
  color: var(--clr-white);
  font-size: var(--text-medium);
  text-align: center;
  margin: 5px 0;
}

/* Blog pagination button */
.pagination {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  margin: 1rem 0;
}

.pagination_btn {
  padding: 1rem 1.5rem;
  color: var(--clr-light-green);
  display: flex;
  align-items: center;
  font-weight: 600;
  background: black;
  padding: 6px 0.8rem;
  border: 1px solid var(--clr-light-green);
  border-radius: 30px;
}

/* Screen wise layout */
@media (max-width: 1024px) {
  .blog_container_wrapper {
    flex-wrap: wrap-reverse;
  }

  .blog_container {
    width: 80%;
  }

  .blog_wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blog_container {
    width: 100%;
  }
}
