.game-guide_hero {
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 580px;
  margin: 0 auto;
}

.game-guide_hero h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: var(--text-large);
  font-weight: 700;
}

.game-guide_hero article {
  max-width: 100%;
}

@media screen and (min-width: 764px) {
  .game-guide_hero h1 {
    font-size: var(--text-xl);
  }
}
