.leagues_links_list .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.leagues_links_list .container > * {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-top: 0.8rem;
}

.leagues_links_list a img {
  width: 65px;
}

/* game guide */

@media screen and (min-width: 764px) {
  .leagues_links_list {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .leagues_links_list .container > * {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    margin-top: 0rem;
  }
}
