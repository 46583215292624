.login {
  height: 50vh;
  display: grid;
  place-content: center;
}

.login_form {
  width: 380px;
  padding: 5px;
}

.login_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.login_input > label {
  width: 100%;
  font-size: var(--text-medium);
  margin: 5px 0;
}
.login_input > input {
  display: block;
  width: 100%;
  font-size: var(--text-medium);
  border: 1px solid var(--clr-light-green);
  border-radius: 5px;
  padding: 4px 8px;
}

.login_form button {
  color: var(--clr-light-green);
  display: flex;
  align-items: center;
  font-weight: 600;
  background: black;
  padding: 6px 1.5rem;
  border: 1px solid var(--clr-light-green);
  border-radius: 5px;
}

.login_error {
  background-color: var(--clr-rose);
  padding: 4px 2px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 1rem;
}

@media (max-width: 640px) {
  .login_form {
    width: 100%;
  }
}
