.create-leage {
  margin-top: 1rem;
}
.create-league-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.new-leage-box {
  width: 377px;
  height: 403px;
  padding: 36px 24px 36px 24px;
  border-radius: 12px;
  border: 2px;
  background: linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    ),
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );

  border: 2px solid;

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.login-back-btnss {
  margin-bottom: 3rem;
  margin-left: 1rem;
}

.login-back-btnss button {
  color: #121212;
  background-color: #7fda56;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
}

.create-league-header p {
  color: white;

  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
}
.create-league-text span {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
}

.create-league-btns button {
  width: 329px;
  height: 56px;
  padding: 10px;
  border-radius: 6px;

  color: #121212;
  background: #22c55e;
  box-shadow: 0px 10px 15px 0px #08401c26;

  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
}

@media (max-width: 892px) {
  .create-league-container {
    flex-direction: column;
    align-items: center;
  }
  .new-leage-box {
    width: 350px;
  }

  .create-league-header p {
    font-size: 20px;
  }
  .create-league-text span {
    font-size: 14px;
  }
}
