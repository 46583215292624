.joinroom-modal {
  margin: 5rem auto;
  width: 400px;
  min-height: 480px;
  padding: 36px 24px 36px 24px;
  border-radius: 12px;
  border: 2px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  background: linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    ),
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );
  border: 2px solid;

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );
}

.joinroom-modal-input {
  width: 100%;
}

.joinroom-modal-copy-btn {
  width: 100%;
  position: relative;
}
.checkicon {
  position: absolute;
  top: 15px;
  right: 15px;
}
.joinroom-modal-copy-btn button {
  width: 100%;
  background: linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    ),
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
}

.joinroom-modal-input input {
  width: 100%;
  height: 56px;
  padding: 16px, 24px, 16px, 24px;
  border-radius: 8px;

  background: linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    ),
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );

  border: 1px solid;

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );
  color: white;
  font-size: 16px;
  box-shadow: 0px 10px 20px 0px #2f2f2f26;
}

.joinroom-modal-btns-create {
  margin-top: 2rem;
  width: 100%;
}
.joinroom-modal-btns-cancel {
  width: 100%;
}

.joinroom-modal-btns-create button {
  width: 100%;
  height: 56px;
  padding: 10px;
  border-radius: 6px;
  background: #22c55e;
  box-shadow: 0px 10px 15px 0px #08401c26;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #121212;
}
.joinroom-modal-btns-cancel button {
  width: 100%;
  height: 56px;
  padding: 10px;
  border-radius: 6px;
  background: linear-gradient(
    267.25deg,
    rgba(255, 255, 255, 0.1) -5.55%,
    rgba(255, 255, 255, 0.1) 108.35%
  );

  box-shadow: 0px 10px 15px 0px #08401c26;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #d6d6d6;
}

.joinroom-modal-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;

  color: #ffffff;
}

.create-league-icons {
  display: flex;
  gap: 30px;
}

.icons-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(
    267.25deg,
    rgba(255, 255, 255, 0.1) -5.55%,
    rgba(255, 255, 255, 0.1) 108.35%
  );

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .joinroom-modal {
    width: 350px;
  }
}
