.forgetpass {
  width: 550px;
  min-height: 300px;
  margin: 5rem auto;

  padding: 36px 40px 36px 40px;
  border-radius: 12px;
  border: 2px;
  background: linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    ),
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );

  border: 2px solid;

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );
}

.forget-pass-content {
}
.forget-pass-header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.forget-pass-header h4 {
  width: 50%;

  font-size: 55px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.05em;

  color: white;
}
.forget-pass-forms {
  position: relative;
  margin-top: 2rem;
}
.forget-pass-forms input {
  width: 100%;
  border: 1px solid #5a3092;
  background: white;
  padding: 16px 12px;
  color: #12121280;
  border-radius: 5px;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}

.email-icons {
  position: absolute;
  right: 10px;
  top: 15px;
}

.sub-btn {
  margin-top: 1rem;
  padding: 14px 12px;
  width: 100%;
  font-size: 16px;
  color: #121212;
  font-weight: 600;
  background: #22c55e;
  box-shadow: 0px 10px 15px 0px #3d037826;
}

@media screen and (max-width: 600px) {
  .forgetpass {
    width: 350px;
    padding: 25px 25px;
  }

  .forget-pass-header h4 {
    width: 100%;

    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: -0.05em;

    color: white;
  }
}
