.main-room {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 90vh;
  overflow: hidden;
}

.room-btn {
  position: absolute;
  display: flex;
  gap: 5px;
  top: 10px;
  right: 5px;
}
.room-btn button {
  color: white;
  border: 1px solid rgb(30, 116, 30);
  background-color: rgb(31, 109, 31);
}

.all-rooms {
  min-height: 90vh;
  padding: 1rem;
  border-radius: 10px;
  width: 90%;
  margin: 4rem auto 0 auto;
  gap: 10px;
  display: flex;
}
.all-rooms button {
  color: white;
}
.all-rooms .title-btn {
  color: white;
  font-size: 22px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.invitation {
  flex: 3;
  height: 520px;
  background-color: rgba(0.1, 0.1, 0.2, 0.3);
  overflow: scroll;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}
.err-loaders {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.general {
  flex: 3;
  background-color: rgba(0.1, 0.1, 0.2, 0.3);
  height: 520px;
  text-align: center;
  overflow: scroll;
  padding: 5px;
  border-radius: 10px;
}
.general-items {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.texts {
  margin-bottom: 1rem;
  display: flex;
  justify-content: start;
  font-weight: bolder;
  font-size: 16px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.global {
  flex: 3;
  background-color: rgba(0.1, 0.1, 0.2, 0.3);
  height: 520px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.my-room {
  position: relative;
  height: 90vh;
  display: flex;
  justify-content: center;
}

.back-btn {
  position: absolute;
  gap: 5px;
  top: 10px;
  left: 15px;
}
.back-btn button {
  color: white;
  background-color: rgb(22, 71, 22);
}

.room-data {
  margin-top: 5rem;
  width: 720px;
}
.join-modal {
  margin: 7rem auto 0 auto;
  width: 600px;
  height: 400px;
  background-color: rgba(0.1, 0.1, 0.5, 0.5);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 1px white;
}
.join-modal input {
  padding: 10px 15px;
  background-color: white;
  border-radius: 10px;
  color: black;
}

@media screen and (max-width: 1200px) {
  .all-rooms {
    flex-direction: column;
    gap: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .room-data {
    width: 600px;
  }
  .room-btn {
    text-align: center;
  }
  .room-btn button {
    width: 180px;
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .room-data {
    width: 380px;
  }

  .join-modal {
    width: 350px;
  }

  .room-btn button {
    width: 120px;
    font-size: 12px;
  }
}
