.main-league {
  margin: 4rem auto;
  width: 700px;
  min-height: 500px;

  padding: 36px 24px 36px 24px;
  border-radius: 12px;
  border: 2px;
  background: linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    ),
    linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    );
  border: 2px solid;

  border-image-source: linear-gradient(
    269.36deg,
    rgba(224, 224, 224, 0.1) -0.24%,
    rgba(237, 237, 237, 0.1) 100.2%
  );
}

.main-league-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dd-loaders {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}

.classic-league {
}

.classic-league-logo {
  width: 100%;
  border-radius: 32px;
  padding: 14px 44px 14px 44px;
  text-align: center;
  background-color: #000000;
}

.classic-league-logo p {
  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
  color: #22c55e;
}
.classic-league-contents {
  margin-top: 40px;
}

.rank-logo {
  width: 38%;
  display: flex;
  justify-content: space-between;
}
.king-logo {
  display: flex;
  align-items: center;
  gap: 7px;
}
.king-logo p {
  font-size: 16px;

  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
}

.classic-league-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 100px;
  max-height: 250px;
  overflow-y: auto;
}
.classic-league-list li {
  width: 100%;
}

.classic-league-list-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.circle-i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 50%;
}
.classic-league-list-left {
  min-width: 37%;

  margin-left: 4px;
  display: flex;
  gap: 7.5rem;
  align-items: center;
  justify-content: space-between;
}

.left-icons {
  display: flex;
  gap: 15px;
  align-items: center;
}
.left-icons p {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04em;
}
.league-name {
  text-align: start;
}
.league-name p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.league-hr {
  opacity: 0.2;
  border: 0.01rem solid
    linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    );
}

.league-btn {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: -2rem;
}

.league-btn button {
  margin-bottom: -2rem;

  background: linear-gradient(
      269.36deg,
      rgba(224, 224, 224, 0.1) -0.24%,
      rgba(237, 237, 237, 0.1) 100.2%
    ),
    linear-gradient(
      267.25deg,
      rgba(255, 255, 255, 0.1) -5.55%,
      rgba(255, 255, 255, 0.1) 108.35%
    );
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .main-league {
    width: 500px;
  }

  .rank-logo {
    width: 54%;
  }

  .classic-league-list-left {
    min-width: 53%;
  }
}
@media (max-width: 600px) {
  .main-league {
    width: 360px;
  }

  .classic-league-list-left {
    gap: 3.7rem;
  }

  .rank-logo {
    width: 56%;
  }
  .classic-league-list-left {
    min-width: 56%;
  }

  .rank-logo p {
    font-size: 14px;
  }
  .classic-league-list-left p {
    font-size: 14px;
  }

  .classic-league-logo {
    padding: 14px 30px 14px 30px;
  }

  .classic-league-logo p {
    font-size: 16px;
  }
}
