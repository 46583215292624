.advertise {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 5rem auto 0 auto;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .advertise {
    width: 400px;
  }
  .advertise p {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .advertise {
    width: 340px;
  }
  .advertise p {
    font-size: 10px;
  }
}
